



































import {
  Component, Vue,
} from 'vue-property-decorator';

import CreditCard from '@/assets/icons/CreditCard.vue';
import Billet from '@/assets/icons/Billet.vue';
import Pix from '@/assets/icons/Pix.vue';

import { INDEX_OPTION_MENU, PAYMENT_METHODS } from '../../constants';

@Component({
  components: {
    CreditCard,
    Billet,
    Pix,
  },
})
export default class MenuPayment extends Vue {
  private PAYMENT_METHODS = PAYMENT_METHODS;

  get creditCardIsActive() {
    return this.PAYMENT_METHODS.find((method) => method.id === INDEX_OPTION_MENU.CREDIT_CART)?.active;
  }

  handleClickChoiceMenu(methodId: number) {
    this.setActiveMethod(methodId);
    this.$store.commit('setChoiceIndexMenu', methodId);
  }

  setActiveMethod(methodId: number) {
    this.PAYMENT_METHODS = this.PAYMENT_METHODS.map((method) => {
      if (method.id === methodId) return { ...method, active: true };
      return { ...method, active: false };
    });
  }
}
