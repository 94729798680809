












































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import { TheMask } from 'vue-the-mask';

import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import { AddressForm } from '../../interfaces/IForms';

import Information from '@/assets/icons/Information.vue';

@Component({
  components: {
    TheMask,
    FeedbackRequiredField,
    Information,
  },
})
export default class FormAddress extends Vue {
  @Prop({ required: true }) data!: AddressForm;
  @Prop({ required: true }) title!: string;

  public hasZipCode = false;
  public hasStreet = false;
  public hasState = false;
  public hasCity = false;
  public hasNeighborhood = false;
  public hasNumber = false;

  @Watch('data.zipcode')
  zipcodeIsEmpty() {
    this.hasZipCode = this.data.zipcode === '';
  }

  @Watch('data.street')
  streetIsEmpty() {
    this.hasStreet = this.data.street === '';
  }

  @Watch('data.state')
  stateIsEmpty() {
    this.hasState = this.data.state === '';
  }

  @Watch('data.city')
  cityIsEmpty() {
    this.hasCity = this.data.city === '';
  }

  @Watch('data.neighborhood')
  neighborhoodIsEmpty() {
    this.hasNeighborhood = this.data.neighborhood === '';
  }

  @Watch('data.number')
  numberIsEmpty() {
    this.hasNumber = this.data.number === '';
  }

  handleSearchZipCode() {
    const url = 'https://buscacepinter.correios.com.br/app/endereco/index.php?t';

    window.open(url);
  }
}
