






















import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import { TheMask } from 'vue-the-mask';

import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import { BilletForm } from '../../interfaces/IForms';

@Component({
  components: {
    TheMask,
    FeedbackRequiredField,
  },
})
export default class FormBillet extends Vue {
  @Prop({ required: true }) data!: BilletForm;

  public hasCpf = false;

  @Watch('data.cpf')
  cpfIsEmpty() {
    this.hasCpf = this.data.cpf === '';
  }

}
